// @ts-strict-ignore
import { Box, Container, Flex, OldText, breakpoints } from '@gassan-ui'
import { getKontentImage } from '@lib/kontent'
import { Author as AuthorModel } from '@lib/kontent/models/Author'
import { rem } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'

const Image = styled.img`
  height: 2rem;
  width: 2rem;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  margin-right: 0.75rem;

  @media screen and (min-width: ${breakpoints.small}) {
    height: 2.5rem;
    width: 2.5rem;
    margin-right: 1rem;
  }
`

type Props = AuthorModel

const Author: FC<Props> = ({ image, job_title, name }) => {
  return (
    <Container mb={{ _: '1.5rem', small: '3.5rem' }} maxWidth={rem(860)}>
      <Box display="flex" alignItems="center">
        <Image
          src={getKontentImage(image.value[0].url, { width: 60 })}
          alt={image.value[0].description}
        />
        <Flex flexDirection="column" justifyContent="space-between">
          <OldText mb={['0', '0.25rem']} variant="regularBold">
            {name.value}
          </OldText>
          <OldText mb="0" variant="small" color="shades.500">
            {job_title.value}
          </OldText>
        </Flex>
      </Box>
    </Container>
  )
}

export default Author
