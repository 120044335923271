// @ts-strict-ignore
import { Fields } from './Fields'
import { Values } from './types'
import { getSchema } from './utils'
import { useLocalization } from '@components/Localization'
import { useNotifications } from '@components/NotificationsProvider'
import { Box, Button, Container, OldHeading, OldText } from '@gassan-ui'
import {
  useAdminEventFormQuery,
  useCreateFormSubmissionMutation,
  useFormSubmissionQuery,
  useMeQuery,
} from '@generated'
import { capitalizeWords } from '@lib/capitalize-words'
import { cn } from '@lib/cn'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { CbAdminEventForm } from '@lib/kontent/models/CbAdminEventForm'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC, useState } from 'react'

type Props = CbAdminEventForm

const AdminEventForm: FC<Props> = ({ title, description, form_id }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation(['notifications', 'forms', 'other', 'navigation'])
  const [formQuery] = useAdminEventFormQuery({ variables: { id: form_id.value } })
  const [successfullySubmitted, setSuccessfullySubmitted] = useState(false)
  const { parseGraphQlError } = useLocalization()
  const [meQuery] = useMeQuery()

  const [submissionQuery] = useFormSubmissionQuery({
    variables: { id: form_id.value },
    pause: !!meQuery?.data?.me,
    requestPolicy: 'network-only',
  })
  const previousSubmission = submissionQuery.data?.userFormSubmission

  const notifications = useNotifications()
  const [, submitForm] = useCreateFormSubmissionMutation()

  const initialValues: Values = {
    firstName: previousSubmission?.firstName ?? '',
    lastName: previousSubmission?.lastName ?? '',
    email: previousSubmission?.email ?? meQuery?.data?.me?.email ?? '',
    postalCode: previousSubmission?.values.postalCode ?? '',
    city: previousSubmission?.values.city ?? '',
    street: previousSubmission?.values.street ?? '',
    country: previousSubmission?.values.country ?? '',
    houseNumber: previousSubmission?.values.houseNumber ?? '',
    houseNumberSuffix: previousSubmission?.values.houseNumberSuffix ?? '',
    phoneNumber: previousSubmission?.values.phoneNumber ?? '',
    message: previousSubmission?.values.message ?? '',
    dietaryRequirements: previousSubmission?.values.dietaryRequirements ?? '',
    numberOfPersons: previousSubmission?.values.numberOfPersons ?? '',
    heardOfGassanVia: previousSubmission?.values.heardOfGassanVia ?? '',
    date: previousSubmission?.values.preferredDate ?? '',
    time: previousSubmission?.values.preferredTime ?? '',
    newsletterSignup: previousSubmission?.values.newsletterSignup ?? false,
    plazaBrilliantNews: previousSubmission?.values.plazaBrilliantNews ?? false,
  }

  if (!formQuery.data?.form) return null

  const schema = getSchema(formQuery.data.form.fields)
  const { redirectAfterSubmission } = formQuery.data.form
  const redirects = {
    en: redirectAfterSubmission?.en ?? undefined,
    nl: redirectAfterSubmission?.nl ?? undefined,
    de: redirectAfterSubmission?.de ?? undefined,
  }

  return (
    <Container mb={{ _: '2rem', small: '4rem', large: '5rem' }} maxWidth={rem(854)}>
      <Box mb={['1.5rem', '2rem']}>
        <OldHeading variant="h3" as="h3" textAlign={['center', 'left']} mb="0">
          {title.value}
        </OldHeading>
        {description.value && (
          <OldText variant="regular" textAlign={['center', 'left']} mt={['1rem', '1rem']}>
            {description.value}
          </OldText>
        )}
      </Box>

      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={async (values: typeof initialValues, { resetForm }) => {
          if (previousSubmission) return
          const { data, error } = await submitForm({
            id: form_id.value,
            input: {
              firstName: values.firstName ? capitalizeWords(values.firstName) : '',
              lastName: values.lastName ? capitalizeWords(values.lastName) : '',
              email: values.email ? values.email : '',
              values: {
                city: values.city ? values.city : '',
                street: values.street ? values.street : '',
                postalCode: values.postalCode ? values.postalCode : '',
                country: values.country ? values.country : '',
                houseNumber: values.houseNumber ? values.houseNumber : '',
                houseNumberSuffix: values.houseNumberSuffix ? values.houseNumberSuffix : '',
                phoneNumber: values.phoneNumber ? values.phoneNumber : '',
                message: values.message ? values.message : '',
                newsletter: values.newsletterSignup,
                plazaBrilliantNews: values.plazaBrilliantNews,
                dietaryRequirements: values.dietaryRequirements,
                numberOfPersons: values.numberOfPersons,
                heardOfGassanVia: values.heardOfGassanVia,
                preferredDate: values.date,
                preferredTime: values.time,
                referrer: window.location.pathname,
              },
            },
          })
          if (error) {
            if (process.env.NODE_ENV === 'development') {
              console.error(error)
            }
            notifications.addNotification({
              variant: 'error',
              label: parseGraphQlError(error.message),
            })
          } else if (data && data.createFormSubmission) {
            notifications.addNotification({
              variant: 'confirmation',
              description: t('requestSent', { ns: 'notifications' }),
              label: t('weWillContactYou', { ns: 'notifications' }),
            })
            setSuccessfullySubmitted(true)

            if (['preview', 'production'].includes(process.env.NEXT_PUBLIC_STAGE)) {
              genericPushToTrackingLayer('AdminEventForm', 'Send')
            }

            if (redirects[language]) {
              window.location.href = redirects[language]
            }
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form id={form_id.value}>
            <fieldset disabled={!!previousSubmission || successfullySubmitted}>
              <div
                className={cn('grid grid-cols-[repeat(12,1fr)] gap-x-4', '[&_section]:col-span-12')}
              >
                <Fields
                  formId={form_id.value}
                  fields={formQuery.data.form.fields}
                  selectableDates={formQuery.data.form.selectableDates}
                  selectableTimes={formQuery.data.form.selectableTimes}
                  maxPersons={formQuery.data.form.customNumberOfPersons}
                  newsletterText={undefined}
                />
                <Button variant="dark" type="submit" status={isSubmitting ? 'loading' : 'idle'}>
                  {t('submits.sendMessage', { ns: 'forms' })}
                </Button>
              </div>
            </fieldset>
          </Form>
        )}
      </Formik>
    </Container>
  )
}

export default AdminEventForm
