import * as styles from './Fields.styles'
import { Text } from '@components/gassan-ui/Typography/Text'
import { Field } from '@gassan-ui'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type OptinOptoutFormFieldsProps = {}

export const OptinOptoutFormFields: FC<OptinOptoutFormFieldsProps> = () => {
  const { t } = useTranslation('global-items')
  return (
    <>
      <div className={styles.item}>
        <Field
          mb="0"
          name="optin100"
          label={
            <div className={styles.label}>
              <Text variant="regular" className="mb-0">
                {t('gassan_newsletter_news_title')}
              </Text>
              <Text variant="regular" className="mb-0 text-shade-500">
                {t('gassan_newsletter_news_label')}
              </Text>
            </div>
          }
          field="checkbox"
          gridColumn="span 12"
        />
      </div>
      <div className={styles.item}>
        <Field
          mb="0"
          name="optin101"
          label={
            <div className={styles.label}>
              <Text variant="regular" className="mb-0">
                {t('gassan_newsletter_events_title')}
              </Text>
              <Text variant="regular" className="mb-0 text-shade-500">
                {t('gassan_newsletter_events_label')}
              </Text>
            </div>
          }
          field="checkbox"
          gridColumn="span 12"
        />
      </div>
      <div className={styles.item}>
        <Field
          mb="0"
          name="optin102"
          label={
            <div className={styles.label}>
              <Text variant="regular" className="mb-0">
                {t('choices_newsletter_title')}
              </Text>
              <Text variant="regular" className="mb-0 text-shade-500">
                {t('choices_newsletter_label')}
              </Text>
            </div>
          }
          field="checkbox"
          gridColumn="span 12"
        />
      </div>
    </>
  )
}
