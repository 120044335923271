// @ts-strict-ignore
import { Box, Button, Container, OldHeading, Image, OldText } from '@gassan-ui'
import { getAspectRatioImage } from '@lib/get-image-aspect-ratio'
import { getKontentImage } from '@lib/kontent'
import { AssetsElement } from '@lib/kontent/models/Elements'
import { rem } from 'polished'
import { FC } from 'react'

type Props = {
  title?: string
  description: string
  image: AssetsElement
  tabletImage: AssetsElement
  linkText?: string
  linkHref?: string
  order: 'text-image' | 'image-text'
  variant?: 'large' | 'regular'
}

const TextImageBoxed: FC<Props> = ({
  title,
  description,
  image,
  tabletImage,
  linkText,
  linkHref,
  order,
  variant = 'regular',
}) => {
  return (
    <>
      <Container
        maxWidth={rem(1450)}
        display="grid"
        gridTemplateColumns={{
          _: '1fr',
          small: '1fr 1fr',
          large: order === 'text-image' ? '1fr 1.7fr' : '1.7fr 1fr',
        }}
        alignItems="center"
        gridGap={{ _: '1.5rem', small: '2.5rem', large: '6rem' }}
        flexDirection={order === 'text-image' ? 'row' : 'row-reverse'}
        justifyItems="space-between"
        mb={{ _: '2rem', small: '4rem', large: '5rem' }}
      >
        <Box
          textAlign={{
            _: variant === 'regular' ? (order === 'text-image' ? 'left' : 'right') : 'center',
            small: order === 'text-image' ? 'left' : 'right',
          }}
          gridColumn={{ _: 'initial', small: order === 'text-image' ? 1 : 2 }}
          gridRow={{ _: 2, small: 1 }}
        >
          {variant === 'regular' ? (
            <>
              {title && (
                <OldHeading variant="h3" as="h3">
                  {title}
                </OldHeading>
              )}
              <OldText style={{ whiteSpace: 'pre-line' }} mb="0">
                {description}
              </OldText>
            </>
          ) : (
            <OldHeading variant="h3" as="h3" mb="0" style={{ whiteSpace: 'pre-line' }}>
              {description}
            </OldHeading>
          )}
          {linkText && linkHref && (
            <Box mt={{ _: '1.5rem', large: '2rem' }} display="inline-block">
              <Button variant="dark" as="link" href={linkHref}>
                {linkText}
              </Button>
            </Box>
          )}
        </Box>
        <Box gridColumn={{ _: 'initial', small: order === 'text-image' ? 2 : 1 }} gridRow={1}>
          <Image
            style={{ aspectRatio: `${getAspectRatioImage(image)}` }}
            className="block md:hidden lg:block"
            alt={image.value[0].description}
            sources={[
              getKontentImage(image.value[0].url, { width: 600 }),
              getKontentImage(image.value[0].url, { width: 600 }),
              getKontentImage(image.value[0].url, { width: 700 }),
              getKontentImage(image.value[0].url, { width: 900 }),
            ]}
          />
          <Image
            style={{ aspectRatio: `${getAspectRatioImage(tabletImage)}` }}
            className="hidden md:block lg:hidden"
            alt={tabletImage.value[0].description}
            sources={[getKontentImage(tabletImage.value[0].url, { width: 500 })]}
          />
        </Box>
      </Container>
    </>
  )
}

export default TextImageBoxed
