import FeaturedImage from './FeaturedImage'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Heading } from '@components/gassan-ui/Typography/Heading'
import { Button, Container, LinariaBox, Link, OldText } from '@gassan-ui'
import { cn } from '@lib/cn'
import { HomepageCbBookATour } from '@lib/kontent/models/HomepageCbBookATour'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import Usp from './Usp'

type Props = HomepageCbBookATour

const HomepageBookATour: FC<Props> = ({ title, description, image }) => {
  const { t } = useTranslation('other')
  return (
    <LinariaBox bg={{ _: 'white', small: 'shade50' }}>
      <Container
        className={cn(
          'grid max-w-[90rem] auto-cols-auto auto-rows-auto items-center gap-2 py-8 text-center',
          'sm:py-12 sm:text-left',
          'sm:grid-cols-[2fr_1fr] md:py-20',
          'lg:grid-cols-[1fr_1fr] lg:gap-3 lg:py-28',
        )}
      >
        <div
          className={cn(
            'col-start-1 row-start-1 ml-0 contents max-w-[35rem]',
            'md:ml-12',
            'lg:block',
          )}
        >
          <div className="grid-row-1">
            <Heading variant="h2" as="h2" className="mb-3 lg:mb-4">
              {title.value}
            </Heading>
          </div>
          <div
            className={cn(
              'col-start-auto row-start-2 mx-auto max-w-[85vw]',
              'sm:grid-column-1 sm:max-w-ful sm:col-start-1 sm:mx-0',
              'md:max-w-[80%]',
              'xl:max-w-auto',
            )}
          >
            <OldText>{description.value}</OldText>
          </div>
          <div
            className={cn(
              'col-start-1 row-start-4 w-auto',
              'sm:col-start-2 sm:row-start-2',
              'md:col-start-2',
            )}
          >
            <ul className={cn('my-2 w-max', 'sm:my-0', 'lg:my-4')}>
              <Usp icon="globe" label={t('availableInLanguages')} />
              <Usp icon="wheelchair" label={t('weelchairFriendly')} />
              <Usp icon="wifi" label={t('freeWifi')} />
            </ul>
          </div>
          <div className={cn('col-start-1 row-start-5 mt-3 block', 'sm:hidden')}>
            <LocalizedLink href="/tours">
              <Button variant="dark" className="min-w-full">
                {t('viewAllTours')}
              </Button>
            </LocalizedLink>
          </div>
          <div className={cn('col-start-1 row-start-3 hidden', 'sm:block')}>
            <LocalizedLink href="/tours">
              <Link pl="0!important">{t('viewAllTours')}</Link>
            </LocalizedLink>
          </div>
        </div>
        <div
          className={cn(
            'col-start-1 row-start-auto',
            'sm:col-span-2 sm:col-start-1',
            'md:row-start-4',
            'lg:col-start-2 lg:row-start-1',
          )}
        >
          <FeaturedImage alt={title.value} src={image.value[0].url} />
        </div>
      </Container>
    </LinariaBox>
  )
}

export default HomepageBookATour
