// @ts-strict-ignore
import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'
import { Box, Span, OldText, VideoPlayer, breakpoints } from '@gassan-ui'
import { useBreakpoint } from '@lib/hooks/useBreakpoint'
import { getKontentImage } from '@lib/kontent'
import { CbVideo as CbVideoProps } from '@lib/kontent/models/CbVideo'
import { FC } from 'react'

type Props = CbVideoProps

const Video: FC<Props> = ({
  desktop_video,
  mobile_video_height,
  mobile_video_width,
  desktop_video_width,
  desktop_video_height,
  mobile_preview_image,
  desktop_preview_image,
  mobile_video,
  display_wide_boxed,
  rich_annotation_text,
}) => {
  const hasRichContent = rich_annotation_text.value
    ? strippedHtmlLength(rich_annotation_text.value) > 0
    : false
  const isDesktop = useBreakpoint(breakpoints.large)
  const isBoxed = display_wide_boxed.value[0].codename === 'boxed_variant'
  const previewImage = isDesktop ? desktop_preview_image : mobile_preview_image
  const video = isDesktop ? desktop_video : mobile_video
  const width = isDesktop ? desktop_video_width.value : mobile_video_width.value
  const height = isDesktop ? desktop_video_height.value : mobile_video_height.value

  return (
    <>
      <Box
        m={isBoxed && '0 auto'}
        width={isBoxed && '54rem'}
        maxWidth={isBoxed && { _: '100%', small: 'calc(100% - 4rem)', large: 'calc(100% - 2rem)' }}
      >
        <VideoPlayer
          placeholderSrc={getKontentImage(previewImage.value[0]?.url, { width: 50 })}
          images={[
            getKontentImage(previewImage.value[0].url, { width: 600 }),
            getKontentImage(previewImage.value[0].url, { width: 500 }),
            getKontentImage(previewImage.value[0].url, { width: isBoxed ? 700 : 1000 }),
            getKontentImage(previewImage.value[0].url, { width: isBoxed ? 700 : 2000 }),
          ]}
          videoSrc={video.value[0]?.url}
          ratio={height / width}
          isFixedRatio
          maxWidth="initial"
        />
        {(hasRichContent ||
          (video.value[0]?.description && video.value[0].description.length > 0)) && (
          <OldText
            pl={isBoxed ? { _: '1rem', small: '0' } : { _: '1rem', small: '2rem', large: '1rem' }}
            my={['0.5rem', '1rem']}
            variant="small"
            color="shades.500"
          >
            {hasRichContent ? (
              <RichContentField {...rich_annotation_text} textColor="grey" />
            ) : (
              <Span>{video.value[0].description}</Span>
            )}
          </OldText>
        )}
      </Box>
    </>
  )
}

export default Video
