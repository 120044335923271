import RichContentField from './RichContentField'
import { strippedHtmlLength } from './stripped-html-length'
import { Container, OldHeading } from '@gassan-ui'
import { CbIsolatedText } from '@lib/kontent/models/CbIsolatedText'
import { rem } from 'polished'
import { FC } from 'react'

type Props = CbIsolatedText

const IsolatedText: FC<Props> = ({ description, alignment, rich_content }) => {
  const hasRichContent = rich_content.value ? strippedHtmlLength(rich_content.value) > 0 : false
  const flexAlign =
    alignment.value[0].codename === 'left'
      ? 'flex-start'
      : alignment.value[0].codename === 'center'
      ? 'center'
      : 'flex-end'
  const textAlign =
    alignment.value[0].codename === 'left'
      ? 'left'
      : alignment.value[0].codename === 'center'
      ? 'center'
      : 'right'
  return (
    <Container
      mb={{ _: '2rem', small: '3rem', large: '5rem' }}
      maxWidth={rem(1450)}
      display="flex"
      justifyContent={flexAlign}
    >
      {hasRichContent ? (
        <RichContentField {...rich_content} size="large" />
      ) : (
        description.value && (
          <OldHeading
            variant="h4"
            as="h4"
            mb="0"
            maxWidth="35.5rem"
            textAlign={textAlign}
            style={{ whiteSpace: 'pre-line' }}
          >
            {description.value}
          </OldHeading>
        )
      )}
    </Container>
  )
}

export default IsolatedText
